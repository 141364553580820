<template>
  <router-view></router-view>
  <SessionExpiredDialog />
  <VerificationSuccessfulDialog />
  <VerificationFailedDialog />
  <DeforestationCheckFailedDialog />
  <DeforestationCheckSuccessfulDialog />
  <VerificationGoingOnDialog />
  <CrsDetectionGoingOnDialog />
  <CrsDetectionFailedDialog />
  <DeforestationCheckGoingOnDialog />
  <ErrorTemplateDialog />
  <Toast />
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import SessionExpiredDialog from './components/common/SessionExpiredDialog.vue';
import VerificationSuccessfulDialog from "./components/common/VerificationSuccessfulDialog.vue";
import VerificationFailedDialog from "./components/common/VerificationFailedDialog.vue";
import DeforestationCheckFailedDialog from './components/common/DeforestationCheckFailedDialog.vue';
import DeforestationCheckSuccessfulDialog from './components/common/DeforestationCheckSuccessfulDialog.vue';
import VerificationGoingOnDialog from './components/common/VerificationGoingOnDialog.vue';
import CrsDetectionGoingOnDialog from './components/common/CrsDetectionGoingOnDialog.vue';
import CrsDetectionFailedDialog from './components/common/CrsDetectionFailedDialog.vue';
import DeforestationCheckGoingOnDialog from './components/common/DeforestationCheckGoingOnDialog.vue';
import ErrorTemplateDialog from './components/common/ErrorTemplateDialog.vue';

const toast = useToast();
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

.p-rowgroup-header {
  background-color: #efefef;
  font-weight: bold;
}

/* Custom styles for PrimeVue DataTable selected row */
.p-datatable .p-datatable-tbody>tr.p-highlight {
  background-color: #ebfdee !important;
  /* Change this color to your desired background color */
  color: #232323 !important;
}

.p-card {
  border: 1px solid #d0d0d0;
  /* Darker border color */
}

.light-green-nadar {
  background-color: #A1E888;
  border-color: #A1E888;
  color: #151615;
}

.light-green-nadar:hover {
  background-color: #89e269;
}

.light-green-nadar-color {
  color: #A1E888;
}

/*
.p-dropdown {
  border-radius: 20px;
}

.p-multiselect {
  border-radius: 20px;
}

.p-inputtext {
  border-radius: 20px;

}
*/
</style>